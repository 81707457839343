import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')
];

export const server_loads = [0,2,3,5,6,7,8,9,10];

export const dictionary = {
		"/": [11],
		"/audio": [12,[2]],
		"/audio/bedtime_story/home": [13,[2]],
		"/audio/bedtime_story/play/[type]": [14,[2]],
		"/audio/mindfulness/home": [16,[2]],
		"/audio/mindfulness/[type]": [15,[2]],
		"/health/error": [17,[3]],
		"/health/food-preferences": [18,[3]],
		"/health/meal-info": [19,[3]],
		"/health/meal-info/error": [20,[3]],
		"/health/snap-permission": [22,[3]],
		"/health/snap": [21,[3]],
		"/horoscope": [~23,[4]],
		"/hydration-tracker": [24,[5]],
		"/hydration-tracker/pref": [25,[5,6]],
		"/movie/error": [26,[7]],
		"/movie/home/[page]": [27,[7]],
		"/movie/recommendation": [28,[7]],
		"/music": [~29,[8]],
		"/music/artist/[artistId]": [~30,[8]],
		"/music/artist/[artistId]/album/[albumId]": [~31,[8]],
		"/music/playlist/[playlistId]": [~32,[8]],
		"/recipe": [33,[9]],
		"/recipe/error": [34,[9]],
		"/recipe/pref": [35,[9,10]],
		"/recipe/recipe-details/[dish]": [36,[9]],
		"/recipe/search": [37,[9]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';